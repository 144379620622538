import { Box, Button, IconButton, Modal } from "@mui/material";

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { getFocus, useAtom, useGet } from "state/jotai";
import { active_project, map_State } from "state/store.global";
import { _project } from "state/store.projects";

import Mapbox from "./Mapbox";
import { Icon } from "assets/Icons_";
import { FullScreenButton } from "./Mapbox.style";
import { position } from "polished";
import zIndex from "@mui/material/styles/zIndex";
import { Icon as Icons } from "components/Icons";
const style = {
  position: "absolute",
  top: { xs: "50%", lg: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "calc(100% - 32px)", lg: "80%" },
  boxShadow:
    "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none",
  height: "80vh", // Adjust the height to fit the map
};

const MapModalButton = () => {
  const [open, setOpen] = useAtom(map_State);

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

//   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // useEffect(() => {
    
 
  //   // Sync the local state with the filter state atom
  //   setOpen(map_State);
  // }, [map_State]);
  
  return (
    <>
      {/* {!open && (
      <FullScreenButton
        onClick={()=>setOpen(true)}
      
      >
         <Icon
              isFaIcon={false}
              size={"xl"}
              icon="full-screen"
              color={clr?.buttons}
            />
          
  
      </FullScreenButton>
      )} */}
      <Modal
        open={open}
        onClose={handleClose}
        sx={{
          ".MuiBackdrop-root": {
            backgroundColor: `${clr.primary}bf`,
            height: '100vh',
          },
        }}
      >
        <Box sx={style}>
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{
              zIndex: "1301",
              position: "absolute",
              right: 0,
              top: -40,
              color: "#fff",
            }}
          >
            <Icons icon="close" fontSize="inherit" />
          </IconButton>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: "6px",
              overflow: "hidden",
            }}
          >
            <Mapbox /> {/* Render your Mapbox component */}
          </Box>
        </Box>
      </Modal>
    </>
  );
};

MapModalButton.propTypes = {
  // Add prop-types if needed for dynamic behavior
};

export { MapModalButton };
