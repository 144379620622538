import { Box, Typography, } from "@mui/material";
import ContactForm from "components/Contact/ContactForm";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useLocation } from "react-router-dom";

function SignUpForm({ unit }) {
  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const here         = useLocation().pathname
  const atUnit       = here.includes('unit')

  return (
    <Box id="unit_page_contact_form" sx={ (theme)=> ({
      pt: {xs: 3, md: 5}, pb: { md: '24px', xs: 0 }, pl: { xs: 2, md: 5.625 }, pr: { xs: 2, md: 10 },  width: "100%",
      [theme.breakpoints.down('1314')]:{
        pr: '40px'
      },
      [theme.breakpoints.down('1024')]:{
        pr: '16px',
        pl: '16px'
      },
    }) } >
      <Box sx={{ 
        width: "100%"
        // maxWidth: { lg: 500, xl: 648 },
        //  mx: "auto", 
         }} >
        <ContactForm campaignId={'a01891bb96de7ceab1b5d86de0bc8ddd'} grid unit={unit}>
          <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', gap: '16px' }} >
          <Box className="h3" > 
              {
                atUnit ?
                buttonsLabel?.filter( btn => btn.UILabelProperty ===  "footerHeading1")[0]?.Label
                :
                buttonsLabel?.filter( btn => btn.UILabelProperty ===  "contactDrawerHeading1")[0]?.Label
              }
            </Box>
            <Box className="paragraph1-regular">
            { buttonsLabel?.filter( btn => btn.UILabelProperty ===  (atUnit ? 'BookShowingHeading' : 'SidefodformularUnderoverskrift'))[0]?.Label }
            </Box>
          </Box>
        </ContactForm>
      </Box>
    </Box>
  );
}

SignUpForm.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default SignUpForm;
