import { Box, Button, IconButton, Modal } from "@mui/material";
import { Icon } from "components/Icons";
import PropTypes from "prop-types";
import React from "react";
import Unit from "./Unit.model";
import { getFocus, useAtom, useGet } from "state/jotai";
import { active_project, current_language, map_State } from "state/store.global";
import { _project } from "state/store.projects";

const style = {
  position: "absolute",
  top: { xs: '30%', lg: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 'calc(100% - 32px)', lg: '80%'},
  // maxWidth: { md: "80%", xs: "100%" },
  boxShadow: "0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)",
  outline: "none"
};

const NavMapModal = () => {
  const setOpen = useAtom(map_State)[1];

  const language = useAtom(current_language)[0];

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button
       disableRipple
       disableFocusRipple
        onClick={handleOpen}
        sx={{
            margin: 0,         // Remove any margin
            paddingLeft:"8px",
            paddingRight:"0px",
            minWidth: 0,       // Reset default MUI button width
            color: clr?.primary_text,
        //   height: '40px',
        //   flexDirection: "row",
        //   gap: '5px',
        //   borderRadius: "6px",
        //   color: "#000000",
          py: "0px",
        //   px: "16px",
        //   lineHeight: 1,
        //   fontWeight: 400,
        //   fontSize: 14,
        boxShadow: "none",
          transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out;",
          "&:hover": {
            // backgroundColor: `${clr?.buttons_text}bf`,
            // color: "#000000",
            transform: "scale(1.1)",
            opacity: 1,
            boxShadow: "none",
          },
        }}
      >
        
        <Icon icon="map"  size={"24px"} />
       
      </Button>
    </div>
  );
};

NavMapModal.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export { NavMapModal };
