import { Box, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Address from "./Address";
import Buttons from "./Buttons";
import CTA from "./CTA";
import Description from "./Description";
import Downloads from "./Downloads";
import { FloorplansModalButton } from "./FloorplansModal";
import Footer from "./Footer";
import Gallery from "./Gallery";
import Highlights from "./Highlights";
import { useUnitHighlight } from "./hooks";
import OpenHouse from "./OpenHouse";
import Price from "./Price";
import Properties from "./Properties";
import RelatedUnits from "./RelatedUnits";
import SignUpForm from "./SignUpForm";
import Statistics from "./Statistics";
import { get, label } from "components/Favorites/Favorites.Utilities";
import Unit from "./Unit.model";
import { PoweredBy } from "components/PoweredBy/PoweredBy";
import ReservationButton from "./ReservationButton";
import { ImgWrapper } from "components/ImgContainer/ImgWrapper";
import Mapbox from "./Mapbox";
import NewReservationButton from "./NewReservationButton";
import MapboxNew from "./MapBoxNew";
import { MapModalButton } from "./FullscreenMap";
import { map_State } from "state/store.global";
import { useAtom } from "jotai";
import { MapBoxModalButton } from "./MapBoxModal";


const UnitPageDesktop = ({ unit }) => {
  const unitId          = unit.getId();
  const address         = unit.getAddress();
  const townAndZipcode  = unit.getTownZipCode();
  const [open, setOpen] = useAtom(map_State);

  const images     = unit.getImages();
  const status     = unit.getStatus();
  const highlights = useUnitHighlight(unit);
  const isRent     = unit.isRent();
  const price      = unit.getPrice();

  const properties = unit.getProperties();
  const descTitle  = unit.getDescriptionTitle();

  const description     = unit.getDescription();
  const areaDescTitle   = unit.getAreaDescriptionTitle();
  const areaDescription = unit.getAreaDescription();

  const unitImage          = images[2]?.url || "";
  const areaImage          = images[images.length - 1]?.url || "";
  const filteredProperties = properties.filter(
    (property) => property?.order !== 0
  );

  return (
    <Box>
      <Grid container alignItems = "stretch">
        {/* gallery */}
        <Grid
          item
            // md={8.5}
          sx={{
            backgroundColor: "#dddddd",
            position       : "relative",
            width          : {
              xs: "calc(100% - 450px)",
            },
          }}
        >
          <Gallery images = {images.slice(1)} hideThumbs fullHeight />
          <Box
            sx={{
              minWidth: 372,
              position: "absolute",
              bottom  : "24px",
              left    : "24px",
              zIndex  : 5,
            }}
          >
            <Buttons
              unitId  = {unitId}
              address = {
                address || townAndZipcode ? `${address}, ${townAndZipcode}`: ""
              }
              rent       = {price}
              highlights = {highlights}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              bottom  : "24px",
              right   : "24px",
              zIndex  : 5,
              display: "flex",
              gap: "16px"
            }}
          >
            <FloorplansModalButton unit = {unit} />
            {/* <MapBoxModalButton /> */}
          </Box>
        </Grid>

        {/* overview */}
        <Grid
          item
            // md={3.5}
          sx={{
            width: {
              xs: "450px",
            },
            height: "594px",
          }}
        >
          {/* status bar */}
          <Box
            sx={{
              display       : "flex",
              flexDirection : "row",
              justifyContent: "end",
              padding       : "16px",
            }}
          >
            <Box
              sx={{
                width          : "fit-content",
                padding        : "8px 24px",
                borderRadius   : "6px",
                backgroundColor: `${status.color}e6`,
                color          : "#ffffff",
                fontWeight     : "bold",
              }}
            >
              <span className = "h4" style = {{ color: "#ffffff" }}>
                {status.label}
              </span>
            </Box>
          </Box>

          <OpenHouse />

          <Box sx = {{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <Box
              className = "h3"
              sx        = {{
                  // mb: { md: -3, xs: 2 },
                textAlign: "center",
              }}
            >
              <Address>
                <p>{unit.getAddress()}</p>
                <span>{unit.getTownZipCode()}</span>
              </Address>
            </Box>
            <Box
              sx={{
                  // mt: highlights.length ? 7 : 0,
                mx      : "auto",
                maxWidth: { lg: "100%" },
              }}
            >
              <Highlights items = {highlights} />
            </Box>

            <Box sx = {{ textAlign: "center" }}>
              <Price
                label={
                  isRent
                    ? `${get(unit.data, "LejelejlighedsText")}`
                    :  `${label(unit.data, "Kontantpris")}`
                }
                value = {price}
              />
            </Box>

            <Box sx = {{ padding: "0 50px 31px 50px" }}>
              {/* <CTA /> */}
              {/* <ReservationButton /> */}
              <NewReservationButton />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Grid container alignItems = "stretch">
        {/* properties */}
        <Grid
          item
          md = {3}
          sx = {{
            borderRight: "1px solid rgba(0,0,0,0.05)",
          }}
        >
          <Box
            sx={{
              px: { xl: 6, lg: 3, md: 3 },
              py: { xl: 3, lg: 3, md: 3 },
              my: 0,
            }}
          >
            <Properties groups = {filteredProperties} />
            <Downloads />
          </Box>
        </Grid>

        {/* content */}
        <Grid item md      = {9}>
        <Grid container sx = {{ backgroundColor: "#f3f3f3" }}>
            <Grid
              item
              sx={{
                width: {
                  xs: "calc(100% - 450px)",
                },
                background: `linear-gradient(to top, ${clr.primary}c4 45%, ${clr.primary} 100%)`,
                minHeight : "420px",
                position  : "relative",
              }}
            >
              <Box
                sx={{
                  maxWidth: {
                    xs: "100%",
                    xl: "100%",
                  },
                  height: "100%",
                  flex  : 1,
                }}
              >
                <Description
                  header       = {descTitle}
                  content      = {description}
                  overlayColor = {`${clr.primary}`}
                  color        = {clr.primary_text}
                />
              </Box>
            </Grid>
            <Grid
              item
                // xs={4.925}
              sx={{
                display: "flex",
                width  : {
                    // xs: "42.3%",
                  xs: "450px",
                },
                minHeight: "420px",
              }}
            >
              {unitImage && (
                <ImgWrapper
                  img   = {unitImage}
                  style = {{ height: "100%", objectFit: "cover" }}
                />
              )}
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              sx={{
                display  : "flex",
                width    : { xs: "42.3%", xl: "450px" },
                minHeight: "420px",
              }}
            >
              {areaImage && (
                <ImgWrapper
                  img   = {areaImage}
                  style = {{ height: "100%", objectFit: "cover" }}
                />
              )}
            </Grid>
            <Grid
              item
              sx={{
                width: {
                  xs: "57.7%",
                  xl: "642px",
                },
                minHeight: "420px",
                position : "relative",
                flex     : 1,
              }}
            >
              <Box sx = {{ height: "100%" }}>
                <Description
                  header  = {areaDescTitle}
                  content = {areaDescription}
                  color   = {"#000"}
                />
              </Box>
            </Grid>
          </Grid>

          <Box        mb   = {6}>
          <Statistics unit = {unit} />
          </Box>
          <Box          mb   = {6}>
          <RelatedUnits unit = {unit} />
          </Box>
        </Grid>
      </Grid>

      {/* FOOTER */}
      {/* <Box sx={{ background: `linear-gradient(180deg, ${clr.primary} 0%, ${clr.primary}66 100%)` }}>
            <Box sx         = {{ maxWidth: 1140, mx: "auto" }} > */}
      <Grid
        container
        sx={(theme)=> ({
          background    : `linear-gradient(180deg, ${clr.primary} 0%, ${clr.primary}66 100%)`,
          display       : 'flex',
          justifyContent: 'center',
            // [theme.breakpoints.down('1314')]:{
            //   flexDirection: 'column-reverse'
            // },
        })
        }
      >
        <Box sx={(theme)=> ({ 
          display                         : 'flex',
          width                           : "100%",
          flexWrap                        : "wrap",
          justifyContent                  : 'center',
          [theme.breakpoints.down('1314')]: {
            alignItems   : 'center',
            flexDirection: 'column-reverse'
          },
         })}>
        <Grid item sx={(theme)=> ({ 
           [theme.breakpoints.up('1576')]:{
            display: "flex", maxWidth: '773px',
          },
          [theme.breakpoints.down('1576')]:{
            maxWidth: "765px",
            width   : "39%"
          },
          [theme.breakpoints.down('1314')]:{
            maxWidth: "773px",
            width   : "68%"
          },
           })}>
          <SignUpForm unit = {unit} />
          <Box        sx   = { (theme)=> ({
              display: "none",
          [theme.breakpoints.down('1314')]: {
            display: "block"
          },
        })}>
            <Footer />
          </Box>
        </Grid>
        <Grid item sx={ (theme)=> ({ 
          color                           : "secondary.contrastText", minWidth: '801px', pr: "45px",
          [theme.breakpoints.down('1314')]: {
            pr: "0"
          },
        })}>
          {/* mapbox spcae */}
         <Box
            sx={{
              mt: "40px"
            }}
          >
            {/* {!open && ( 
            <Mapbox />
          )} */}
            {/* <MapboxNew /> */}
        
            {/* <MapModalButton /> */}
           
          </Box>
          <Box        sx   = { (theme)=> ({
              display: "none",
          [theme.breakpoints.up('1314')]: {
            display: "block"
          },
        })}>
            <Footer />
          </Box>
        </Grid>
        </Box>

        <PoweredBy clr = {clr.primary_text} />
      </Grid>
      {/* </Box>
        </Box> */}
    </Box>
  );
};

UnitPageDesktop.propTypes = {
  unit: PropTypes.instanceOf(Unit).isRequired,
};

export default UnitPageDesktop;
