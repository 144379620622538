import { Box, Grid, Typography } from "@mui/material";
import { STATICS } from "api/api";
import React from "react";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";




function Footer() {
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const contactData =
    getFocus(_project, "ContactProperties")?.[pin]?.[pid] ?? [];

  return (
    <div style={{ width: "100%" }}>
      <Box
        sx={{
          pb: 55,
          pr: { lg: 0, xs: 2 },
          pl: { lg: 0, xs: 2 },
          py: { lg: 0, xs: 0 },
          lineHeight: 1.4,
          position: 'relative', // Relative positioning for parent container
          maxWidth: 756,
          margin: "auto",
          mt: 3,
        }}
      >
        <Box
          sx={{ color: '#fff',  mb: 2, }}
        >
          <span className="h3">
            {" "}
            {
              buttonsLabel?.filter(
                (btn) => btn.UILabelProperty === "SidefodKontaktOverskrift"
              )[0]?.Label
            }
          </span>
        </Box>

        {contactData?.map((item, i) => {
          return (
            <Grid container sx={{ mb: 5, gap: "48px" }} spacing={3} key={i}>
              <Grid item 
              // lg={6} xs={12}
               sx={{ pt: "16px !important" }}>
                <Box  sx={{ color: '#fff' }}>
                  <span className="h4"> {item?.Name}</span> <br />
                  <span className="paragraph1-light">
                    {item?.AddressLine1}
                  </span>{" "}
                  <br />
                  <span className="paragraph1-light">
                    {item?.AddressLine2}
                  </span>{" "}
                  <br />
                  <Typography
                    style={{ color: clr.primary_text }}
                    component="a"
                    href={`tel: ${item.Phone}`}
                  >
                    <span className="paragraph1-light" style={{ textDecoration: 'underline', color: clr.primary_text }}>{item?.Phone}</span>
                  </Typography>
                  <br />
                  <Typography
                    style={{ color: clr.primary_text }}
                    component="a"
                    href={`mailto: ${item.Email}`}
                  >
                    <span className="paragraph1-light">{item?.Email}</span>
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                // lg={6}
                // xs={12}
                sx={{
                  textAlign: { lg: "center", xs: "left" },
                  display: 'flex',
                  justifyContent: { xs: "flex-start", lg: 'flex-end'}
                }}
              >
                {" "}
                <Box sx={{ maxWidth: 182
                  // { xs: "130px", lg: "250px" }
                   }}>
                  <Box component="img" src={`${STATICS}${item?.ImageUrl}`} style={{ cursor: 'pointer' }} />
                </Box>
              </Grid>
            </Grid>
          );
        })}

        {/* <div style={{ height: 50 }} /> */}
      </Box>
    </div>
  );
}

export default Footer;
