import { Box, colors } from "@mui/material";
import { Icon } from "assets/Icons_";
import React, { useState } from "react";
import styled from "styled-components";

const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);


const DirectionPopupContainer = styled.div`

padding: 15px;
color  : black;

`;

const PopupTopAmenity = styled.div`
 margin-bottom: 10px;
  
`;

const PopupHeader = styled.div`
 margin-bottom: 15px;
  
`;

const PopupPlaceName = styled.div`
  //    margin-bottom: 10px;
  
`;

const TransportModesContainer = styled.div`

  background     : white;          /* Set background to white */
  height         : 50px;           /* Set your desired height */
  display        : flex;
  justify-content: space-between;
  gap            : 5px;
  align-items    : center;         /* Optional: center content vertically */
`;

const TransportMode = styled.div`
  display    : flex;
  align-items: center;

  transition: 0.3s;
  padding   : 0 15px;
  cursor: pointer;
  height: 100%;
  ${({isSelected}) => isSelected && 'background-color: #efefef'}

`;



const TransportDuration = styled.span`
  margin-left: 10px;
  
  color: ${({clr}) => clr?.buttons };
`;

const CustomPopup = ({ handleTravelModeChange, poi, walkingDuration, cyclingDuration, drivingDuration ,clr}) => {
  const [mode, setMode] = useState('drive')
  return (
    <Box>
    <DirectionPopupContainer>
    <PopupTopAmenity className = "h6">{capitalizeFirstLetter(poi.properties.amenity)}</PopupTopAmenity>
    <PopupHeader     className = "h2">{poi.properties.name}</PopupHeader>
      </DirectionPopupContainer>
      <TransportModesContainer>
        <TransportMode isSelected={mode === 'walk'} onClick={()=> {handleTravelModeChange('walk'); setMode('walk')}} >
        <Icon              color     = {clr?.buttons} size      = {"xl"} isFaIcon = {true}   icon = "walking"/>
        <TransportDuration className = "paragraph1-regular" clr = {clr}> {walkingDuration} min.</TransportDuration>
        </TransportMode>
        <TransportMode isSelected={mode === 'cycle'} onClick={()=> {handleTravelModeChange('cycle'); setMode('cycle')}}>
        <Icon              color     = {clr?.buttons} size      = {"xl"} isFaIcon = {true} icon = "cycling"/>
        <TransportDuration className = "paragraph1-regular" clr = {clr}> {cyclingDuration} min.</TransportDuration>
        </TransportMode>
        <TransportMode isSelected={mode === 'drive'} onClick={()=> {handleTravelModeChange('drive'); setMode('drive')}}>
        <Icon              color     = {clr?.buttons} size      = {"xl"}  isFaIcon = {true} icon = "driving"/>
        <TransportDuration className = "paragraph1-regular" clr = {clr}> {drivingDuration} min.</TransportDuration>
        </TransportMode>
      </TransportModesContainer>
      </Box>
    
  )
};

export default CustomPopup;
