import { styled, TextField } from "@mui/material";

export const styles = {
  valueContainer: (provided, state) => {
    return {
      ...provided,
      overflow: "visible",
      backgroundColor: "#F9F9F9",
      borderRadius: 8,
      fontFamily: "Cerebri sans-light !important",
      fontSize: 14,
      display: "flex",
      justifyContent: "flex-start",
      // alignItems: "center",
      alignItems: "end",
      padding: "0px",
      "& input": {
        width: 0,
        height: 0
      },
      width: "calc(100% - 80px)",
      height: '42px',
      marginLeft: "14px",
      ".selectedValueContainer": {
        cursor: state?.isDisabled ? "not-allowed" : "cursor",
      },
    };
  },
  singleValue: (baseStyles)=> ({
    ...baseStyles,
    lineHeight: 3,
    fontSize: 14,
    "@media only screen and (max-width: 600px)": {
      fontSize: 16,
      lineHeight: 2.5
    },
    fontFamily: "Cerebri sans-light",
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    fontSize: 14,
    fontWeight: 300,
    boxShadow: state.isFocused ? "none" : "none",
    borderRadius: "6px 0px 0px 6px",
    backgroundColor: "#F9F9F9",
    borderColor: state.isFocused ? "#000" : state.selectProps.error ? "#C7393C" :  '#D1D1D1',
    "&:hover": {
      borderColor: "#D1D1D1",
      filter: "drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.12))"
    },
    pointerEvents: state.isDisabled && "auto",
    cursor: state.isDisabled ? "not-allowed " : "pointer",
    minHeight: "44px",
  }),
  placeholder: (base, state) => {
    return {
      ...base,
      position: state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused ||
      state.selectProps.menuIsOpen ? "absolute" : "relative",
      background:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused ||
        state.selectProps.menuIsOpen
          ? `linear-gradient(0deg, #F9F9F9 41%, rgba(247,247,247,0) 45%)`
          : "transparent",
      // borderRadius: border === "none" ? "unset" : 2,
      // padding:
      //   state.hasValue ||
      //   state.selectProps.inputValue ||
      //   state.selectProps.isFocused ||
      //   state.selectProps.menuIsOpen
      //     ? "0px 4px"
      //     : "0px 1px",
      margin: "0px",
      color: (state.selectProps.isFocused || state.hasValue || state.selectProps.inputValue || state.selectProps.menuIsOpen) ? "#000" : "#000",
      top:
        state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused ||
        state.selectProps.menuIsOpen
          ? "-7.9px"
          : "-1px",
      lineHeight: 1,
      transition: "top 0.2s",
      fontSize: (state.selectProps.isFocused || state.hasValue || state.selectProps.inputValue || state.selectProps.menuIsOpen) ? 10 : 14,
      fontFamily: "Cerebri sans-light",
      letterSpacing: 0,
      fontWeight: 300,
    }
  },
  indicatorSeparator: (base, state) => ({
    ...base,
    width: "0px",
  }),
  menu: (baseStyles, state) => ({
      ...baseStyles,
      zIndex: 5,
      border: "1px solid #D1D1D1",
      marginTop: "4px"
    }),
  option: (base, { isDisabled, isSelected, selectProps, ...state }) => {
    const getIndex = state?.options.findIndex(
      (s) => state?.data?.value === s?.value
    );
    return {
      ...base,
      backgroundColor: isSelected ? "#F9F9F9" : "#fff",
      zIndex: 1,
      fontSize: 14,
      fontFamily: "Cerebri sans-light !important",
      letterSpacing:"0",
      color: isDisabled ? "#888888" : "#000000",
      padding: "10px 16px",
      borderBottom:
        state?.options?.length - 1 === getIndex ? "" : "1px solid #D1D1D1",
      cursor: isDisabled ? "not-allowed" : "pointer",

      "&:active": {
        backgroundColor: '#F9F9F9'
      },
      "&:hover": {
        backgroundColor: '#F9F9F9'
      }
    };
  },
  clearIndicator: (baseStyles, state) => ({
    cursor: state.isDisabled ? "not-allowed " : "pointer",
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: "175px", // Adjust the value to your desired maximum height
    overflowY: "auto", // Add vertical scroll if needed,
    cursor: "pointer",
    padding: 0
  }),
  dropdownIndicator: (base, state) => {
    return {
      ...base,
      color: "#000000",
      transition: "all .2s ease",
      transform: state.selectProps.isFocused || state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
      padding: "6px 19px 6px 19px",
      cursor: "pointer",
    }
  },
  // indicatorsContainer: (base, state) => {
  //   return {
  //     ...base,
  //     color: "#000000",
  //     padding: "6px",
  //     cursor: "pointer",
  //   }
  // },
}


export const PlaceHolderInputField = styled(TextField)(({ theme }) => {
  return ({
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "0px 6px 6px 0px",
    },
    "& legend": {
      fontSize: "10px",
    },
    "& .MuiInputBase-input": {
      borderRadius: "0px 6px 6px 0px",
      position: "relative",
      fontFamily: "Cerebri sans-light !important",
      backgroundColor: "#FFF",
      fontSize: 14,
      width: "100%",
      padding: "10.5px 16px 10.5px 16px",
      height: "44px",
      transition: "border-color 0.3s",
    },
    "& .MuiInputLabel-formControl": {
      color: "#000",
      fontFamily: "Cerebri sans-light !important",
      fontSize: "14px",
      fontWeight: 500,
      transform: "translate(14px, 11px)"
    },
    "& .MuiInputLabel-formControl.Mui-focused": {
      color: '#000',
      fontSize: "10px",
      transform: "translate(14px, -7px)"
    },
    "& .MuiFormLabel-filled.MuiInputLabel-formControl": {
      color: '#000',
      fontSize: "10px",
      transform: "translate(14px, -7px)"
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "0px 6px 6px 0px",
        border: "1px solid #D1D1D1",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: '#D1D1D1',
      filter: "drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.2))"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #000 !important"
    }
  })
});
