import styled from 'styled-components';
import { BREAK_ } from 'constants'


export const Container = styled.div.attrs(props => ({
  open: props.open,
}))`
 
  border-radius:  6px;
  position: relative; /* Make the container relative for the map */

  box-sizing: border-box;
    &.open {
    min-height: 100%;
  }

  &.closed {
    min-height: 416px;
  }

  .mapboxgl-popup{
    transform:none !important;
  }
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-center .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip, 
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip{
    display:none !important;
}

  .mapboxgl-popup-content {
    border-radius: 20px;
    overflow: hidden;
  }

 ${ BREAK_.md_dn } { 
     border-radius: 0px 0px 0px 0px;
     margin : 0 auto;
  }

  /* Hide the Mapbox logo using CSS */
  .mapboxgl-ctrl-logo {
    display: none !important; /* Ensure that the Mapbox logo is completely hidden */
  }
     
  .mapboxgl-popup-content {
  ${({open, isDesktop}) =>  open && `min-width: ${!isDesktop ? 310 : 400}px`}
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 16px;
  gap: 10px; /* Add space between buttons */
  // z-index: 9; /* Keep buttons above the map */
  padding: 0 16px;
  justify-content: space-between;
`;

export const ResetButton = styled.button`
  position: relative;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-color: #FFFFFFbf;
  color: white;
  border: 1px solid #D1D1D1;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
   padding: 2px 4.5px 2px 4.5px; 

  &:hover {
    transform: scale(1.1);
    background-color: #FFF;
  }
`;

export const FullScreenButton = styled.button`
position: absolute;
  z-index: 9;
  right: 16px;
    bottom: 88px;

  width: 40px;
  height: 40px;

  background-color: #FFFFFFbf;
  color: white;
  border: 1px solid #D1D1D1;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding :10px;

  &:hover {
    transform: scale(1.1);
    background-color: #FFF;
  }
`;

export const ToggleButton = styled.button`
 position: relative;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-color: #FFFFFFbf;
  color: white;
  //  border: 2px solid ${(props) => (props.isSatellite ? "black" : "white")};
   border: 1px solid #D1D1D1;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0; 

  &:hover {
    transform: scale(1.1);
    // background-color: #222;
  }


img {
    width: 100%; /* Make image take full width */
    height: 100%; /* Make image take full height */
    object-fit: contain; /* Maintain aspect ratio */
     border-radius: 6px;
  }

`;

export const PopupContainer = styled.div`
  position: absolute;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 110;
  width: 200px;
  transform: translate(-50%, -100%); /* Center horizontally and shift up vertically */
  pointer-events: none; /* Disable interaction with the popup */
`;


// Pill-shaped container for zoom buttons
export const ZoomControlsContainer = styled.div`
  // position: absolute;
  // bottom: 0px;
  // right: 40px;
  width: 42px;
  height: 81px;
  border : 1px solid #D1D1D1;
  background-color: #FFFFFFBF;
  border-radius: 6px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: none;

  z-index: 9; /* Keep above the map */
  //  ${ BREAK_.md_dn } { 
  // right: 35px;
  
     
  // }

  @media (max-width: 1660px) {
    right: 40px; /* Keep a consistent right position for screens less than 1660px */
  }

`;

// Individual button styles
export const ZoomInButton = styled.button`
 position: relative;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-color: #FFFFFFBF;
border :none;
  border-radius: 6px 6px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  border-bottom: 1px solid #D1D1D1;
  padding : 10px;
  &:hover {
    background-color: #f7f7f5;
  }

 
`;
export const ZoomOutButton = styled.button`
 position: relative;
  z-index: 9;
  width: 40px;
  height: 40px;
  background-color:  #FFFFFFBF;
border : none;
  border-radius: 0px 0px 6px 6px;
  padding : 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;

  &:hover {
    // transform: scale(1.1);
    background-color: #f7f7f5;
  }

 
`;

