import "mapbox-gl/dist/mapbox-gl.css";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { createRoot } from "react-dom/client";
import mapboxgl from "mapbox-gl";
import MapboxDirections from "@mapbox/mapbox-gl-directions/dist/mapbox-gl-directions";
      // import supermarketicon from "../../assets/poi_icons/shopping-basket.png";
      // import cafe from "../../assets/poi_icons/coffee-cup.png";
import mainMarkerIcon from "../../assets/poi_icons/svgviewer-png-output.png";
      // import busStation from "../../../public/estatetool/poi_icon/bus.svg";
      // import metroStation from "../../assets/poi_icons/metro-station.png";
      // import trainStation from "../../assets/poi_icons/DSB.png";
import satelliteIcon from "../../assets/poi_icons/satelitte.webp";
import mapIcon from "../../assets/poi_icons/map.webp";
import { debounce } from "lodash";
import "./mapbox.css";

import {
  ButtonsContainer,
  Container,
  FullScreenButton,
  ResetButton,
  ToggleButton,
  ZoomControlsContainer,
  ZoomInButton,
  ZoomOutButton,
} from "./Mapbox.style";
import CustomPopup from "./DirectionPopup";
import { Icon } from "assets/Icons_";
import { map_State } from "state/store.global";
import { useAtom } from "jotai";

import { RES, STATICS } from "api/api";
import { Box } from "@mui/material";
import { MapModalButton } from "./FullscreenMap";
import { useBreak } from "hooks/useBreak";

function Mapbox() {
  const isDesktop                             = useBreak('md_up')
  const mapContainerRef                       = useRef(null);
  const mapRef                                = useRef(null);
  const directionsRef                         = useRef(null);
  const [isSatelliteView, setIsSatelliteView] = useState(false);
  const [categoryIcons, setCategoryIcons]     = useState({});
  const [open, setOpen]                       = useAtom(map_State);
  const originalCenter                        = useRef([0, 0]);
  const originalZoom                          = useRef(0);
  const previousBounds                        = useRef(null);
  const zoomThreshold                         = 10;
  const markersRef                            = useRef(new Map());
  const poiDataCache                          = useRef(null);

  const amenityZoomThresholds = {
    supermarket  : 13,
    cafe         : 14,
    bus_station  : 10,
    railway_train: 11,
    subway       : 12,
    restaurant   : 15,
    school       : 15,
    kindergarten : 16,
    university   : 16
  };

  const amenityData = {
    subway: {
      color: '#C41E3A'
    },
    railway_train: {
      color: '#C41E3A'
    },
    bus_station: {
       color: '#000000'
    },
    supermarket: {
        color: '#000000'
    },
    restaurant: {
      color: '#000000'
    },
    cafe: {
      color: '#000000'
    },
    kindergarten: {
      color: '#000000'
    },
    school: {
      color: '#000000'
    },
    university: {
      color: '#000000'
    }
  }

  const fetchPOIData = useCallback(async () => {
    if (poiDataCache.current) return poiDataCache.current;

    try {
      const response = await fetch(
        `${RES}osm-geojson/amenity_points.geojson`
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data                 = await response.json();
            poiDataCache.current = data;
      return data;
    } catch (error) {
      console.error("Error loading GeoJSON:", error);
      return null;
    }
  }, []);

  useEffect(() => {
    const initializeMap = async () => {
      try {
        const response = await fetch(
          "https://pratik.dev.api.estatetool.net/v1/projects/15"
        );
        const data     = await response.json();
        const iconssvg = data?.MapboxConfig[0]?.IconJson;
     
        setCategoryIcons(iconssvg);
        const mapboxToken = data.MapboxConfig[0].Token;
      
        const styleURL          = data?.MapboxConfig[0]?.Style;
        const satelliteStyleURL = "mapbox://styles/mapbox/satellite-v9";
        const centerCoordinates = 
          data.MapboxConfig[0].MapCenter.split(",").map(Number);
        const zoomLevel = 14;
        const bearing   = data.MapboxConfig[0].Bearing;
        const pitch     = data.MapboxConfig[0].Pitch;
        
        mapboxgl.accessToken = mapboxToken;

        const map = new mapboxgl.Map({
          container         : mapContainerRef.current,
          style             : isSatelliteView ? satelliteStyleURL: styleURL,
          center            : centerCoordinates,
          zoom              : zoomLevel,
          bearing           : bearing,
          pitch             : pitch,
          attributionControl: false,
          minZoom           : 9,
          maxZoom           : 20,
          pixelRatio        : window.devicePixelRatio,
        });
   
        originalCenter.current = centerCoordinates;
        originalZoom.current   = zoomLevel;
        mapRef.current         = map;
        const el                        = document.createElement("div");
              el.className              = "main-marker";
              el.style.width            = "70px";
              el.style.height           = "70px";
              el.style.backgroundSize   = "contain";
              el.style.backgroundRepeat = "no-repeat";
              el.style.cursor           = "pointer";
              el.style.zIndex           = "10";

              const svg = `
                <svg class = "Iconsstyle__Svg-sc-tyy9yp-2 edzURJ" viewBox = "0 0 1000 1000" width = "34px" height = "47px">
                  <path
                    d    = "m 1004.8433,331.7043 c 0,232.47724 -304.00352,646.36024 -437.31271,817.1431 a 78.495429,80.352411 0 0 1 -123.19791,0 C 311.02349,978.06454 7.0200075,564.18154 7.0200075,331.7043 c 0,-281.914396 223.5124125,-510.71448 498.9116325,-510.71448 275.39921,0 498.91166,228.800084 498.91166,510.71448"
                    fill = ${clr.primary}
                    id   = "path1"
                    />
                  <path
                    id   = "rect1"
                    fill = "#ffffff"
                    d    = "m 505.93164,-8.7720199 v 0 c 0,0 332.60775,0 332.60775,340.4763199 v 0 c 0,0 0,340.47632 -332.60775,340.47632 v 0 c 0,0 -332.60776,0 -332.60776,-340.47632 v 0 c 0,0 0,-340.4763199 332.60776,-340.4763199"
                    />
                  <path
                    id   = "path2"
                    d    = "m 514.09161,132.86613 a 12.417356,12.711116 0 0 0 -16.31995,0 L 288.36182,321.98937 a 12.328661,12.620322 0 1 0 16.31995,18.8851 l 28.82601,-25.96701 v 155.25721 c 0,34.81938 27.53992,63.01082 61.55461,63.01082 h 221.7385 c 34.05903,0 61.6433,-28.19144 61.6433,-63.01082 V 314.90746 l 28.78166,25.96701 A 12.332191,12.623935 0 1 0 723.5458,321.94398 Z M 358.12075,470.16467 V 292.57222 L 505.93164,159.10551 653.78687,292.57222 v 177.59245 c 0,20.88254 -16.58604,37.81557 -36.98598,37.81557 H 567.57494 V 381.86781 A 24.612974,25.195248 0 0 0 542.91762,356.67257 H 468.99 a 24.612974,25.195248 0 0 0 -24.65732,25.19524 V 507.98024 H 395.10673 A 36.985982,37.860966 0 0 1 358.12075,470.16467 M 468.99,507.98024 V 381.86781 h 73.92762 v 126.11243 z"
                    fill = ${clr.primary}
                    />
                </svg>
              `;

                // Set the SVG as the innerHTML of the div
              el.innerHTML = svg;

        new mapboxgl.Marker(el)?.setLngLat(centerCoordinates).addTo(map);
        el.addEventListener("click", resetMap);
  
        const lineBaseWidth = 14;
        const directions = new MapboxDirections({
          accessToken: mapboxgl.accessToken,
          unit       : "metric",
          profile    : "mapbox/driving",
          interactive: false,
          controls   : { inputs: false, instructions: false },
          styles     : [
            {
              id: "directions-route-line",
              type: "line",
              source: "directions",
              layout: {
                "line-cap" : "round",
                "line-join": "round",
                "line-color": clr.primary,
                "line-width": 6 
              },
              paint: {
                "line-color"  : clr.primary,   // Color of the route line
              //   'line-pattern': 'pattern-dot',
              //   'line-width': [
              //     'interpolate',
              //     ['exponential', 2],
              //     ['zoom'],
              //     0,
              //     lineBaseWidth * 1,
              //     0.9999,
              //     lineBaseWidth * 2,
              //     1,
              //     lineBaseWidth * 1,
              //     1.9999,
              //     lineBaseWidth * 2,
              //     2,
              //     lineBaseWidth * 1,
              //     2.9999,
              //     lineBaseWidth * 2,
              //     3,
              //     lineBaseWidth * 1,
              //     3.9999,
              //     lineBaseWidth * 2,
              //     4,
              //     lineBaseWidth * 1,
              //     4.9999,
              //     lineBaseWidth * 2,
              //     5,
              //     lineBaseWidth * 1,
              //     5.9999,
              //     lineBaseWidth * 2,
              //     6,
              //     lineBaseWidth * 1,
              //     6.9999,
              //     lineBaseWidth * 2,
              //     7,
              //     lineBaseWidth * 1,
              //     7.9999,
              //     lineBaseWidth * 2,
              //     8,
              //     lineBaseWidth * 1,
              //     8.9999,
              //     lineBaseWidth * 2,
              //     9,
              //     lineBaseWidth * 1,
              //     9.9999,
              //     lineBaseWidth * 2,
              //     10,
              //     lineBaseWidth * 1,
              //     10.9999,
              //     lineBaseWidth * 2,
              //     11,
              //     lineBaseWidth * 1,
              //     11.9999,
              //     lineBaseWidth * 2,
              //     12,
              //     lineBaseWidth * 1,
              //     12.9999,
              //     lineBaseWidth * 2,
              //     13,
              //     lineBaseWidth * 1,
              //     13.9999,
              //     lineBaseWidth * 2,
              //     14,
              //     lineBaseWidth * 1,
              //     14.9999,
              //     lineBaseWidth * 2,
              //     15,
              //     lineBaseWidth * 1,
              //     15.9999,
              //     lineBaseWidth * 2,
              //     16,
              //     lineBaseWidth * 1,
              //     16.9999,
              //     lineBaseWidth * 2,
              //     17,
              //     lineBaseWidth * 1,
              //     17.9999,
              //     lineBaseWidth * 2,
              //     18,
              //     lineBaseWidth * 1,
              //     18.9999,
              //     lineBaseWidth * 2,
              //     19,
              //     lineBaseWidth * 1,
              //     19.9999,
              //     lineBaseWidth * 2,
              //     20,
              //     lineBaseWidth * 1,
              //     20.9999,
              //     lineBaseWidth * 2,
              //     21,
              //     lineBaseWidth * 1,
              //     22,
              //     lineBaseWidth * 2
              //   ]
              "line-width"  : 5,         // Adjust thickness (default is around 8; set lower for thinner lines)
              "line-opacity": 0.8, 
              },
              filter: ["all", ["in", "$type", "LineString"], ["==", "route", "selected"]],
            },
            {
              id: "directions-route-line-casing",
              type: "line",
              source: "directions",
              layout: {
                "line-cap": "round",
                "line-join": "round",
              },
              paint: {
                "line-width"  : 0,         // Adjust thickness (default is around 8; set lower for thinner lines)
                "line-opacity": 0.8,  
              },
              filter: ["all", ["in", "$type", "LineString"], ["==", "route", "selected"]],
            },
          ],
        });

        directionsRef.current = directions;
        
        map.addControl(directions);

        map.addControl(new mapboxgl.NavigationControl());

        map.on("load", debounce(() => {

          directions.on("route", () => {
          const map = mapRef.current;
            // Remove the default markers created by the Directions API
          const markerLayers = ["directions-origin-point","directions-origin-label", "directions-destination-label", "directions-destination-point"];

          markerLayers.forEach((layer) => {
            if (map?.getLayer(layer)) {
              map.removeLayer(layer);
            }
            if (map?.getSource(layer)) {
              map.removeSource(layer);
            }
          });
        });
          addPOIMarkers(map,iconssvg, centerCoordinates);
        }, 400));
        
        // map.on(
        //   "moveend",
        //   debounce(() => {
        //     addPOIMarkers(map,iconssvg, centerCoordinates);
        //   }, 700)
        // );

        map.on(
          "zoom",
          debounce(() => {
            toggleMarkersVisibility(map);
          }, 300)
        );
      } catch (error) {
        console.error("Error fetching the API:", error);
      }
    };

    initializeMap();

    return () => {
      if (mapContainerRef.current) mapContainerRef.current.innerHTML = "";
    };
  }, [isSatelliteView]);

  const addPOIMarkers = useCallback(
    async (map,iconssvg, centerCoordinates) => {

      const categories  = ["bus_station", "cafe","supermarket","railway_train","subway", "restaurant", "school", "kindergarten", "university"];
      const bounds      = map.getBounds();
      const currentZoom = map.getZoom();
            // const iconssvg = data?.MapboxConfig[0]?.IconJson;
      if (
        previousBounds.current &&
        previousBounds.current?.contains(bounds) &&
        currentZoom < zoomThreshold
      ) {
        return;
      }
     
      previousBounds.current = bounds;

      const pois = await fetchPOIData();
      if (!pois || !pois.features) return;

      let resruarantCount = 0
      let cafeCount       = 0

      pois.features.forEach((poi) => {
        if (
          categories.includes(poi.properties.amenity) &&
          poi.geometry.type === "Point"
        ) {
          const coordinatesString = poi.geometry.coordinates.join(",");
          const amenityType          = poi.properties.amenity;
          const markerZoomThresholds = amenityZoomThresholds[amenityType];

          if (markersRef.current.has(coordinatesString)) {
            const existingMarker = markersRef.current.get(coordinatesString);
            if (currentZoom >= zoomThreshold) {
              existingMarker.addTo(map);
            } else {
              existingMarker.remove();
            }
            return;
          }

          if(poi.properties.amenity === "restaurant") resruarantCount++
          else if(poi.properties.amenity === "cafe")  cafeCount++

          if(resruarantCount > 100 && poi.properties.amenity === "restaurant") return
          else if(cafeCount > 100 && poi.properties.amenity === "cafe") return

          const el                       = document.createElement("div");
                el.className             = "poi-marker";
                el.style.width           = "30px";
                el.style.height          = "30px";
                el.style.backgroundColor = "white";
                el.style.borderRadius    = "50%";
                // el.style.display = "flex";
          el.style.justifyContent = "center";
          el.style.alignItems     = "center";
          el.style.transition     = "box-shadow 0.2s ease-in-out";
          el.style.cursor         = "pointer";
         
            // el.style.boxShadow      = `${clr.primary} 0px 0px 0px 2.5px`
          el.markerProperties = poi.properties
   
          const icon                       = document.createElement("div");

          const root = createRoot(icon);
          root.render(<Icon icon={poi.properties.amenity} size="20" color={amenityData[poi.properties.amenity]?.color} />);
     
          if (currentZoom >= markerZoomThresholds) {
                  // Show markers
            el.style.display = "flex";
          }else {
            el.style.display = "none";
          }
          

          el.appendChild(icon);


          const popup = new mapboxgl.Popup({ anchor: 'bottom-left' });

          const poiMarker = new mapboxgl.Marker(el)

            .setLngLat(poi.geometry.coordinates)
            .setPopup(popup);

          markersRef.current.set(coordinatesString, poiMarker);

          if (currentZoom >= zoomThreshold) {
            poiMarker.addTo(map);
          }

          el.addEventListener("click", async () => {

            el.markerProperties.clicked = true
            
                  el.style.boxShadow = `0 0 0 2.5px ${clr.primary}`;
                  
            const origin             = centerCoordinates;
            const destination        = poi.geometry.coordinates;

            const [drivingResponse, walkingResponse, cyclingResponse] = 
              await Promise.all([
                fetch(
                  `https://api.mapbox.com/directions/v5/mapbox/driving/${origin[0]},${origin[1]};${destination[0]},${destination[1]}?access_token=${mapboxgl.accessToken}`
                ),
                fetch(
                  `https://api.mapbox.com/directions/v5/mapbox/walking/${origin[0]},${origin[1]};${destination[0]},${destination[1]}?access_token=${mapboxgl.accessToken}`
                ),
                fetch(
                  `https://api.mapbox.com/directions/v5/mapbox/cycling/${origin[0]},${origin[1]};${destination[0]},${destination[1]}?access_token=${mapboxgl.accessToken}`
                ),
              ]);

            const [drivingData, walkingData, cyclingData] = await Promise.all([
              drivingResponse.json(),
              walkingResponse.json(),
              cyclingResponse.json(),
            ]);

            if (
              drivingData.routes.length > 0 &&
              walkingData.routes.length > 0 &&
              cyclingData.routes.length > 0
            ) {
              const drivingDuration = Math.round(
                drivingData.routes[0].duration / 60
              );
              const walkingDuration = Math.round(
                walkingData.routes[0].duration / 60
              );
              const cyclingDuration = Math.round(
                cyclingData.routes[0].duration / 60
              );

              const popupContent = document.createElement("div");
              const root         = createRoot(popupContent);
              root.render(
                <CustomPopup
                  handleTravelModeChange   = {handleTravelModeChange}
                  clr             = {clr}
                  poi             = {poi}
                  walkingDuration = {walkingDuration}
                  cyclingDuration = {cyclingDuration}
                  drivingDuration = {drivingDuration}
                />
              );
              
              popup.setDOMContent(popupContent);

              const popupElem                  = popup.getElement();
                    popupElem.style.left       = '16px'
                    popupElem.style.bottom     = '74px'
                    popupElem.style.top        = 'unset'
                    popupElem.style.willChange = 'auto'
                    popupElem.style.zIndex     = '10'

              directionsRef.current.setOrigin(origin);
              directionsRef.current.setDestination(destination);

              const boundss = new mapboxgl.LngLatBounds();
              boundss.extend(origin);
              boundss.extend(destination);
              // Fit the map to the bounds with padding
              map.fitBounds(boundss, {
                padding: { top: 150, bottom: 150, left: 100, right: 100 },
                maxZoom: 14, // Optional: Limit the zoom level
                duration: 1000, // Optional: Animation duration in ms
              });
            }
          });

          popup.on("close", () => {
            directionsRef.current.removeRoutes();
            el.style.boxShadow          = "none";
            el.markerProperties.clicked = false;

            mapRef.current.setPaintProperty('directions-route-line', 'line-color', clr.primary);
            mapRef.current.setPaintProperty('directions-route-line', 'line-dasharray', 6);
           
            map.flyTo({
              bearing: 0,
              pitch  : 0,
              speed  : 0.8,
              curve  : 0,
              easing : (t) => t,
            });
          });
        }
      });
    },
    [categoryIcons, fetchPOIData, zoomThreshold]
  );

  const toggleMarkersVisibility = useCallback(
    (map) => {
      const currentZoom = map.getZoom();
    
      markersRef.current.forEach((marker) => {
        const markerProps = marker._element?.markerProperties;
  
              // Ensure marker properties exist
        if (!markerProps) {
          marker.remove();
          return;
        }
  
              // Always show clicked markers
        if (markerProps.clicked) {
          marker.addTo(map);
          return;
        }
  
              // Get amenity type and corresponding threshold
        const amenityType   = markerProps.amenity;
        const zoomThreshold = amenityZoomThresholds[amenityType];
              // console.log(zoomThreshold, amenityType, currentZoom)
        if (currentZoom >= zoomThreshold) {
                // Show markers
          marker.getElement().style.display = "flex";
        } else {
                // Hide markers, but keep their state intact
          const popupOpen = marker.getPopup()?.isOpen();
          if (!popupOpen) {
            marker.getElement().style.display = "none";
          }
        }
      });
    },
    []
  );
  
  const toggleMapStyle = () => {
    previousBounds.current = null
    setIsSatelliteView(!isSatelliteView);
  };

  const resetMap = () => {
    const map        = mapRef.current;
    const directions = directionsRef.current;
  
    if (map && directions) {
      map.flyTo({
        center : originalCenter.current,
        bearing: 0,
        pitch  : 0,
        zoom   : 14,
      });

      directions.removeRoutes();
    }
  };

  const zoomIn = () => {
    const map = mapRef.current;
    if (map) {
      map.zoomIn();
    }
  };

  const zoomOut = () => {
    const map = mapRef.current;
    if (map) {
      map.zoomOut();
    }
  };

  const handleTravelModeChange = (mode) => {
    updateRouteStyle(mode);
  };

  const updateRouteStyle = (mode) => {
    const style = {
      walk: { 'line-color': clr.primary, 'line-dasharray': [2, 2], 'line-width': 3 },
      cycle: { 'line-color': clr.primary, 'line-width': 3 },
      drive: { 'line-color': clr.primary, 'line-width': 6 },
    };
  
    mapRef.current.setPaintProperty('directions-route-line', 'line-color', style[mode]['line-color']);
    mapRef.current.setPaintProperty('directions-route-line', 'line-width', style[mode]['line-width']);
    if (style[mode]['line-dasharray']) {
      mapRef.current.setPaintProperty('directions-route-line', 'line-dasharray', style[mode]['line-dasharray']);
    } else {
      mapRef.current.setPaintProperty('directions-route-line', 'line-dasharray', null);
    }
  };
  
  return (
    <>
      <Box       sx        = {{ position: 'relative', height: '100%' }}>
      <Container ref       = {mapContainerRef}
                 open      = {open}
                 isDesktop = {isDesktop}
                 className = {open ? 'open' : 'closed'}/>

      <ButtonsContainer>
        <Box         sx      = {{ display: 'flex', gap: '8px', alignItems: "end" }}>
        <ResetButton onClick = {resetMap}>
            <svg class = "Iconsstyle__Svg-sc-tyy9yp-2 edzURJ" viewBox = "0 0 1000 1000" width = "22" height = "30">
            <path
              d    = "m 1004.8433,331.7043 c 0,232.47724 -304.00352,646.36024 -437.31271,817.1431 a 78.495429,80.352411 0 0 1 -123.19791,0 C 311.02349,978.06454 7.0200075,564.18154 7.0200075,331.7043 c 0,-281.914396 223.5124125,-510.71448 498.9116325,-510.71448 275.39921,0 498.91166,228.800084 498.91166,510.71448"
              fill = {clr.primary}
              id   = "path1"
              />
            <path
              id   = "rect1"
              fill = "#ffffff"
              d    = "m 505.93164,-8.7720199 v 0 c 0,0 332.60775,0 332.60775,340.4763199 v 0 c 0,0 0,340.47632 -332.60775,340.47632 v 0 c 0,0 -332.60776,0 -332.60776,-340.47632 v 0 c 0,0 0,-340.4763199 332.60776,-340.4763199"
              />
            <path
              id   = "path2"
              d    = "m 514.09161,132.86613 a 12.417356,12.711116 0 0 0 -16.31995,0 L 288.36182,321.98937 a 12.328661,12.620322 0 1 0 16.31995,18.8851 l 28.82601,-25.96701 v 155.25721 c 0,34.81938 27.53992,63.01082 61.55461,63.01082 h 221.7385 c 34.05903,0 61.6433,-28.19144 61.6433,-63.01082 V 314.90746 l 28.78166,25.96701 A 12.332191,12.623935 0 1 0 723.5458,321.94398 Z M 358.12075,470.16467 V 292.57222 L 505.93164,159.10551 653.78687,292.57222 v 177.59245 c 0,20.88254 -16.58604,37.81557 -36.98598,37.81557 H 567.57494 V 381.86781 A 24.612974,25.195248 0 0 0 542.91762,356.67257 H 468.99 a 24.612974,25.195248 0 0 0 -24.65732,25.19524 V 507.98024 H 395.10673 A 36.985982,37.860966 0 0 1 358.12075,470.16467 M 468.99,507.98024 V 381.86781 h 73.92762 v 126.11243 z"
              fill = {clr.primary}
              />
            </svg>
        </ResetButton>
        <ToggleButton onClick = {toggleMapStyle} isSatellite = {isSatelliteView}>
          <img
            src = {isSatelliteView ? mapIcon : satelliteIcon}
            alt = "Toggle Map Style"
          />
        </ToggleButton>
        </Box>
        <ZoomControlsContainer>
          <ZoomInButton onClick = {zoomIn}>
            <Icon
              isFaIcon = {false}
              size     = {"20"}
              icon     = "plus-icon"
              color    = {"#000"}
            />
          </ZoomInButton>
          <ZoomOutButton onClick = {zoomOut}>
            <Icon
              isFaIcon = {false}
              size     = {"20"}
              icon     = "minus-icon"
              color    = {"#000"}
            />
          </ZoomOutButton>
        </ZoomControlsContainer>
         { !open &&
          <FullScreenButton
            onClick = {()=>setOpen(true)}
          
          >
            <Icon
                  isFaIcon = {false}
                  size     = {"20"}
                  icon     = "full-screen"
                  color    = {"#000"}
                />
          </FullScreenButton>
          }
      
        {/* <MapModalButton /> */}
      </ButtonsContainer>
      </Box>
    </>
  );
}

export default Mapbox;
